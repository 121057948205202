.search {
    h1, h2, h3, h4, h5 {
        margin-bottom: 0;
    }
    a.search-link {
        &:hover {
            text-decoration: none;
            color: $text-color;
        }
    }
    #not-found {
      text-align: center;
      font-weight: bold;
    }
}
